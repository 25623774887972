html{
  font-family: Spartan, sans-serif;
}

.containerEvents {
  display: flex;
  flex-direction: column;
}

.containerTitleSearch {
  display: flex;
}
.tittleEvent {
  color: black;
  padding-top: 12vh;
  padding-left: 15vh;
  font-weight: bold;
}

.inputSearch {
  margin-top: 11.6vh;
  margin-left: 10vh;
}
.img-noEvents{
  text-align: center;

  padding: 10rem;
}

.filterEvents{
  display: flex;
  justify-content: left;
  align-content: center;
  gap: 50px;
  padding-right: 50px;
  
}

.selectEvents{
color: rgb(153, 153, 153);
font-weight: 500;
background-color: black;
border: 1px solid;
border-radius: 2rem;
font-family: Spartan, sans-serif;
padding: 1.2rem 1.2rem 1.2rem 2.5rem;
width: 20rem;
appearance: none;
  background-image: url("../../assets/flecha-select.png");
  background-repeat: no-repeat;
  background-position: right 1.5rem top 50%;
  background-size: 1.8rem auto;
}

.selectEvents:focus{

  color: black;
  background-color: #ebddd2;
}

.selectEvents option{
  margin-top: 20px;
  color: black;
  background-color: #ebddd2;
}

option{
  text-align: center;
}
.selectEvents.opened{
  background-color: blue;
}

#react-select-3-input{
  max-width: 0px !important;

}
.css-6j8wv5-Input{
  max-width: 0px !important;
  max-height: 0px !important;
 margin-left: 20px !important;
 margin-top: -10px !important;
 color: black !important;
}
.filterButtonOpen{
  color: black;
font-weight: 500;
background-color: white;
border: 1px;
display: flex;
  text-align: center;
  align-items: center;
font-family: Spartan, sans-serif;
padding-left: 5rem;
  padding-top: 2rem;
}

.filterButtonOpen:hover {
  cursor: pointer;
  text-decoration: underline black; 
  color: black; /* Texto negro en hover */
  border-color: black; /* Borde negro */
}

.buttonContainer {
  display: flex;
  justify-content: space-between;  /* Espacio entre los botones */
  padding: 10px;
}


.filterButtonApply {
  color: rgba(255, 255, 255, 0.806);
  font-weight: 500;
  background-color: black;
  border: 1px solid black;
  
  font-family: Spartan, sans-serif;
  padding: 10px 20px;
  transition: all 0.3s ease;  /* Transición suave */
}

.filterButtonApply:hover {
  background-color: rgb(255, 255, 255); /* Fondo blanco al pasar el mouse */
  color: black; /* Texto negro en hover */
  border-color: black; /* Borde negro */
}

.filterButtonClean {
  color: black;
  font-weight: 500;
  background-color: white;
  border: 1px solid black;
  
  font-family: Spartan, sans-serif;
  padding: 10px 20px;
  transition: all 0.3s ease;  /* Transición suave */
}

.filterButtonClean:hover {
  background-color: black;  /* Fondo negro en hover */
  color: rgba(255, 255, 255, 0.806);/* Texto blanco en hover */
  border-color: black;  /* Borde negro */
}

.filterButtonApply:disabled {
  background-color: #cdcccc;  /* Cambiar el fondo a gris claro */
  color: #818181;  /* Cambiar el color del texto a gris */
  border-color: #9e9e9e;  /* Cambiar el color del borde a gris */
  cursor: not-allowed;  /* Cambiar el cursor a no permitido */
  opacity: 0.6;  /* Reducir la opacidad */
}
.filterButtonClean:disabled {
  background-color: #cdcccc;  /* Cambiar el fondo a gris claro */
  color: #818181;  /* Cambiar el color del texto a gris */
  border-color: #9e9e9e;  /* Cambiar el color del borde a gris */
  cursor: not-allowed;  /* Cambiar el cursor a no permitido */
  opacity: 0.6;  /* Reducir la opacidad */
}

.dividerStyle{
  width: auto;
  margin-left: 10px !important;
  margin-right: 10px !important;
}

.divFilter{
  width: 500px; 
  padding: 20px;
  z-index: 10
}

.bannerEventImg{
  width: 100%;
  height: auto; /* Mantener la proporción de la imagen */
display: block;
padding-top: 60px;
}
.containerTitleFilter{
  width: 80%;
  color: rgb(162, 161, 161);
  padding-left: 5rem;
  padding-right: 5rem;
}
.hrFilter{
  width: 100% !important;
  padding: 0 5rem !important;
  color: rgb(255, 255, 255) !important
}


@media (max-width: 500px) {
  .containerTitleSearch {
    align-self: center;
  }
  .tittleEvent {
    padding-top: 10vh;
    padding-left: 0;
    font-size: 22px;
    
  }

  .img-noEvents{
    text-align: center;
  padding: 5rem;
  width: 80vw;
  object-fit: cover;
  align-self: center;
    
  }
  .filterEvents{
    display: flex;
    justify-content: center;
    align-content: center;
   margin-bottom: 5px;
    border-top: 1px double  rgba(0,0,0,.08);
border-bottom: 1px double  rgba(0,0,0,.08);
    padding: 0px;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,.08);
-webkit-box-shadow: 0 2px 4px 0 rgba(0,0,0,.08);
-moz-box-shadow: 0 2px 4px 0 rgba(0,0,0,.08);
  }
  .divFilter{
    width: 65vw; 
    padding: 20px;
    z-index: 10
  }

  .filterButtonClean {
    width: 50%;
    margin-right: 2.5px;
  }
  .filterButtonApply{
    width: 50%;
    margin-left: 2.5px;
  }
  /*.buttonContainer{
    display: flex;
  justify-content: space-between;
  padding: 10px;
  bottom: 0;
  position: absolute;
  width: 60vw;
  }*/

  .bannerEventImg{
    width: 100%;
    height: auto; /* Mantener la proporción de la imagen */
  display: block;
  padding-top: 60px;
  }

  .filterButtonOpen{
    border: 1px;
    text-align: center;
    align-items: center;
    padding-left: 0;
    padding-top: 1rem;
  }

}
