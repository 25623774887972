.footerBox{
    margin-top: 20px;
    width: 100%;
   
    position: relative;
    bottom: 0;
}
.iconImage{
    width: 35px;
    height: 35px;
}
.iconButton{
    padding: 0px !important;
}
.hrFooter{
    width: 100%;
    margin: 0px !important;
    padding: 0px !important;
    border: none;
    height: 1px;
    margin: 0;
    flex-shrink: 0;
    background-color: rgba(0, 0, 0, 0.444);
}
.footerText{
    color: rgb(174, 174, 174);
    text-align: center;
    padding-top: 20px;
    font-size: 12px !important;
}
.boxIcon{
    justify-content: space-between;
    gap: 20px;
    padding: 0;
}

.iconButtonFlecha{
    position: fixed;
    bottom: 10px;
    left: 30px;
    padding: 20px;
   
    border-radius: 20px;
    z-index: 1000;
    text-align: left !important;
}

.iconButtonFlecha :hover {
   
}

.iconImageFlecha{
    filter: invert(100%);
    width: 20px;
    height: 20px;
    padding: 5px;
    background-color: rgb(66, 92, 119);
    border-radius: 30px;
    border: 0px
}

.hrSpce{
    max-width: 35%;
    flex-basis: 35%;
}

.bodySpce{
    max-width: 30%;
    flex-basis: 30%;
}

@media (max-width: 500px) {
    .footerBox{
        margin-top: 20px;
        width: 100%;
        
        
        position: relative;
        bottom: 0;
    
    }
    .iconImage{
        width: 25px;
        height: 25px;
    }
    .iconButton{
        padding: 0px !important;
    }
    .hrFooter{
        width: 100%;
        margin: 0px !important;
        padding: 0px !important;
        border: none;
        height: 1px;
        margin: 0;
        flex-shrink: 0;
        background-color: rgba(0, 0, 0, 0.444);
    }
    .footerText{
        color: rgb(174, 174, 174);
        text-align: center;
        padding-top: 20px;
        font-size: 8px !important;
    }
    .boxIcon{
        justify-content: space-between;
        gap: 0px;
    }

    .iconButtonFlecha{
        position: fixed;
        bottom: 10px;
        left: 30px;
        padding: 20px;
        border-radius: 20px;
        z-index: 1000;
        text-align: left !important;
    }

    .hrSpce{
        max-width:16.666667%;
        flex-basis: 16.666667%;
    }
    
    .bodySpce{
        max-width: 66.666667%;
        flex-basis: 66.666667%;
    }
}