@import url("https://fonts.googleapis.com/css2?family=Spartan:wght@400;500&display=swap");


.react-container2 {
  margin-top: 30px;
  display: flex;
  flex: 2;
  flex-direction: column;
  font-family: Spartan, sans-serif;
}
.containerTittle2 {
  width: 43vw;
  height: 30vh;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 0 auto;
}
.button-spacex2{
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5vh;
 }
.buttonForma2{
  width: 20%;
  background: black !important;
  color:white !important

}
.tittle2 {
  font-size: 3vh;
}

.cart-ticket2 {
  background: #eaeaf0;
  border-radius: 10px;
  transition: all 0.25s linear;
  box-shadow: 4px 5px 2px 0px rgba(59, 57, 57, 0.4);
  margin: 0 19vh;
}
.ticket-header2{
  margin: 4vh;
  float: right;
 
}
.ticket-container2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  /* row-gap: 10vh; */
  margin: 0 2vh;
}

.ticket-right2 {
  display: flex;
  margin:5vh auto;
  flex-direction: column;
}

.ticket-left2 {
  display: flex;
  margin: 5vh auto;
  flex-direction: column;
}

.ticket-line2{
  margin:2vh;
}
.cancelar-reserva2{
  display: flex;
  justify-content: center;
  justify-content: space-around;
  font-size: 20px;
}

@media (max-width: 600px) {

  .react-container2 {
    margin-top: 50px;
    display: flex;
    flex: 2;
    flex-direction: column;
    font-family: Spartan, sans-serif;
  }
  .containerTittle2 {
    width: 100%;
    height: 20vh;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 0 auto;
    margin-top:20px;
  }
  .button-spacex2{
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 5vh;
   }
  .buttonForma2{
    width: 60%;
    background: black !important;
    color:white !important
  
  }
  .tittle2 {
    font-size: 3vh;
  }
  
  .cart-ticket2 {
    background: #eaeaf0;
    border-radius: 10px;
    transition: all 0.25s linear;
    box-shadow: 4px 5px 2px 0px rgba(59, 57, 57, 0.4);
    margin: 0 15px;
  }
  .ticket-header2{
    margin: 4vh;
    float: right;
   
  }
  .ticket-container2 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    /* row-gap: 10vh; */
    margin: 0 2vh;
  }
  
  .ticket-right2 {
    display: flex;
    margin:5vh auto;
    flex-direction: column;
  }
  
  .ticket-left2 {
    display: flex;
    margin: 5vh auto;
    flex-direction: column;
  }
  
  .ticket-line2{
    margin:2vh;
  }
  .cancelar-reserva2{
    display: flex;
    justify-content: center;
    justify-content: space-around;
    font-size: 20px;
  }
  
}