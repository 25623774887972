nav {
  width: 100%;
  position: fixed;
  /*top: 0px;
  left: 0px;*/
  height: 60px;
  display: flex;
  align-items: center;
  z-index: 1;
  background-color: #fafafa;
  box-sizing: border-box;
  /*padding: 12px 36px;*/
  border-bottom: whitesmoke 1px solid;
  /*justify-content: center;*/
  gap: 15%;
  flex-direction: row;
  text-decoration: none;
  padding-left: 10rem;
}
.img_header {
  width: 120px;
}
.img_header_calendar{
  width: 40px;
  color: black;
 
}

.linkTitle{
  text-decoration: none;
  color: black;
  font-size: 12px;
}

@media (max-width: 500px) {
  

  nav {
    gap: 23%;
    padding-left: 0rem;
  }

  .img_header_calendar{
    width: 28px;
  }
  .img_header_menu{
    width: 140px;
  }
  .menu{
    
  }
  .menuIcon{
    padding-top: 1.5rem;
  padding-bottom: 1rem;
  }
  .dividerStyle{
    width: auto;
    margin-left: 10px !important;
    margin-right: 10px !important;
  }
  .linkTitle{
    padding-top: 1rem;
    padding-bottom: 1rem;
    font-size: 14px;
    font-weight: 400;
  }
}