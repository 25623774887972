@import url("https://fonts.googleapis.com/css2?family=Spartan:wght@400;500&display=swap");

.react-container {
  margin-top: 80px;
  display: flex;
  flex: 2;
  flex-direction: column;
  font-family: Spartan, sans-serif;
}
.containerTittle {
  width: 43vw;
  height: 20vh;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 0 auto;
}
.button-space {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5vh;
}
.buttonForm {
  width: 50vw;
  background-color: black !important;
  color: white !important;
}
.tittle {
  font-size: 4vh;
}

.cart-ticket {
  background: #eaeaf0;
  border-radius: 10px;
  transition: all 0.25s linear;
  box-shadow: 4px 5px 2px 0px rgba(59, 57, 57, 0.4);
  margin: 0 19vh;
}
.ticket-header-d {
  margin: 0 4vh;
  float: right;
}
.ticket-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  /* row-gap: 10vh; */
  margin: 0 2vh;
}

.ticket-right {
  display: flex;
  margin: 5vh auto;
  flex-direction: column;
}

.ticket-left {
  display: flex;
  margin: 5vh auto;
  flex-direction: column;
}

.ticket-line-d {
  margin: 3vh 0vh 3vh 10vh;

  display: grid;
  justify-content: center;
}
