/*.homeDiv{
  overflow: hidden;
}*/

.container-btn {
  display: grid;
  grid-template-columns: 40% 40%;
  margin-top: 3vh;
  margin-bottom: 1vh;
  justify-items: center;
  justify-content: center;
  column-gap: 30px;
}
.cardButtonNav{
  box-shadow: 0px !important;
}
.bannerHomeImg{
  width: 100%;
  height: auto; /* Mantener la proporción de la imagen */
display: block;
padding-top: 60px;
  padding-bottom: 5px;
}
.divButtonVer{
  display: flex;
  justify-content: center;
 align-content: center;
}
.link{
  text-decoration: none;
}

@media (max-width: 500px) {
  .container-btn {
    display: block;
    margin-top: 10px;
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .bannerHomeImg{
    width: 100%;
    height: auto; /* Mantener la proporción de la imagen */
  display: block;
  padding-top: 60px;
  padding-bottom: 2px;
  }

  .button {
    width: 15vh;
    height: 4.5vh;
    background-color: black;
    color: #ffffff;
    font-size: 1.5vh;
    cursor: pointer;
    border-radius: 20px;
    text-align: center;
  }
  
  .button:hover {
    background-color: rgb(46, 46, 46);
    color: white;
  }
  
  .button:active {
    background-color: rgb(46, 46, 46);
  
    transform: translateY(4px);
  }
  .divButtonVer{
    display: flex;
    justify-content: center;
   align-content: center;
   margin-top: 2rem;
  }

}
