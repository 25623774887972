.react-container {
  margin-top: 10px !important;
  display: flex;
  flex: 2;
  flex-direction: column;
}
.containerTittleConsul {
  width: 43vw;
  height: 20vh;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 0 auto;
}
.container-wrap-consultants {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 2.5vh;
}
.container-text-consultant {
  display: grid;
  grid-auto-rows: 1fr;
  margin: 0 2vh;
}
.consultant {
  display: grid;
  border: 1px solid #fff0;
  border-radius: 10px;
  background-color: lightsteelblue;
  margin: 1vh;
  min-height: 3vh;
  text-align: center;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;
}

.tittleConsul {
  font-size: 4vh;
  font-weight: 600;
}

.zone {
  
  position: relative;
  padding: 12px 19px;
  
}

.zone::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 50%; /* Ancho del borde más corto */
  border-bottom: 2px solid #000; /* Estilo del borde */
  display: none; /* El borde está oculto por defecto */
}

.zone.active::after {
  display: block; /* Muestra el borde solo cuando "active" es true */
}

.divCalendar {
  padding: 0.5rem;
}

.calendar {
  display: flex;
  flex: 8;
  flex-direction: row;
  justify-content: center;
}

.form-calendarr {
  display: flex;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  justify-content: center;
  padding: 4vh 20vw;
  background-color: #EFEFEF;
  min-height: 60vh;
  height: 100%;
}

.input-containerr {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  justify-content: space-around;
  color: black;
}

.div_form {
  min-width: 35%;
}
.grid-container {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.grid-input {
  display: flex;
  flex-direction: column;
  padding: 0.5em;
}

.input {
  border-radius: 5px;
  border: none;
  font-size: 20px;
  color: black !important;
}

.label {
  font-size: 12px;
  font-weight: 500;
  padding: 3px;
}

.grid-calendarr {
  display: grid;
  grid-template-rows: 5vh 0.25fr;
}

.button-save {
  border-radius: 50px;
  background-color: black;
  color: white;
  font-size: 16px;
  width: 190px;
  height: 30px;
}

.container-view {
}

.container-days {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 1vh;
  grid-template-rows: 1fr 1fr;
  row-gap: 1vh;
}

.day {
  border-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  justify-content: center stretch;
  /* box-shadow: 2px 2px grey; */
  background-color: wheat;
  text-align: center;
  font-size: 13px;
  font-weight: 500;
}

.grid-time {
  display: flex;
  width: 20vw;
  flex-direction: column;
  /* height: 38vh; */
}

.grid-calendario {
  display: grid;
 /*grid-template-rows: 1fr 1fr;
  /* row-gap: 10vh; 
  width: 25vw;
  /* flex-direction: column; 
  height: 33vh;*/
  margin-bottom: 1rem;
}

.grid-item-time {
  display: grid;
  grid-template-columns: 1fr 1fr;
  overflow-y: scroll;
}

.item-time {
  display: flex;
  /* width: 30%; */
  border-radius: 6px;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 14px;
  padding: 7px 15px;
  background-color: white;
  margin: 3px;
  flex-grow: 2;
}

.item-time:hover {
  background-color: #dddddd !important;
}

/* .view-calendar {
  border-radius: 10px;
  background-color: aliceblue;
  width: 35vh;
  margin: auto;
  height: 35vh;
} */

.view-calendar {
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
  user-select: none;
  width: 100vw;
  padding: 10px;
  padding-top: 0;
}

@media (min-width: 480px) {
  .view-calendar {
    height: auto;
    width: 340px;
    max-width: 100%;
  }
}

.month-container {
  width: 100%;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr;
  -ms-grid-rows: 1fr;
}

.container-save-btn {
  margin-top: 5vh;
  margin-top: vh;
  justify-content: center;
  display: flex;
}

.buttonFormAgenda {
  width: 95%;
  height: 5vh;
  background-color: black;
  color: white;
  margin: 4vh auto;
  border-radius: 10px;
}

.containerButtonConsult {
  display: flex;
  order: 4;
 
  margin-top: 2rem;
  align-self: center;
 
}

@media (max-width: 500px) {
  .calendar {
    justify-content: left;
    overflow-y: auto !important;
  }
  .zone {
    display: ruby;
    display: ruby-text;
  }
  .form-calendarr {
    display: -webkit-flex;
    flex-direction: column;
    height: auto;
    padding: 0;
    padding-bottom: 2rem;
    padding-top: 2rem;
  }
  .order {
    max-width: 100vw;
    flex-flow: row wrap;
  }
  .input-container {
    order: 3;
  }

  .grid-time {
    display: grid;
    order: 2;
    width: auto !important;
    margin-top: 1rem;
  }

  .grid-item-time {
    display: grid;
    grid-template-columns: 1fr 1fr  !important;
    flex-flow: row wrap;
    margin-bottom: 2rem !important;
    overflow-y: hidden !important;
    padding-left: 5px;
  }

  .containerButtonConsult {
    align-self: center;
    width: 50%;
    margin-top: 1rem !important;
  }
  .grid-calendario {
    grid-template-rows: 1fr;
    height: auto;
    order: 1;
    margin-bottom: 1rem !important;
  }
  .divCalendar {
    padding: 0.5rem;
   
  }
  .containerTittle {
    width: 100vw !important;
    height: 35vh !important;
    background-size: cover !important;
    margin: 5rem 0 2rem 0 !important;
  }
  .react-container {
    margin-top: 0 !important;
  }

  .tittleConsul {
    font-size: 2vh;
   
  }

  .containerTittleConsul {
    width: 80vw !important;
    height: 10vh;
  }

  .container-text-consultant{
    margin: 0 5vh;
  text-align: center;
  font-size: 16px;
  }
}
