.slide {
  width: 100% !important;
  background-size: cover !important;
  background-position: center !important;
 
  cursor: pointer;
}

.event-img {
  width: calc(100% + 30px);
  /* margin: -15px; */
  margin-bottom: 0px;
  height: 300px;
  background-size: cover;
  background-position: center;
  border-radius: 8px;
}

/*.constainer-slides {
  margin-top: 5vh;
  /* font-family: sans-serif; 
}*/

.container-center-btn {
}

.slider-arrow {
  position: absolute;
  height: 100%;
  width: 100%;
}
.arrow-btn {
  top: 45%;
  z-index: 1;
}
.next {
  float: right;
}

.button__bar {
  display: inline-block;
  vertical-align: middle;
  margin: auto;
  padding: 0;
  background: rgba(255, 255, 255, 0.3);
  border-radius: 40px;
  width: 100%;
  text-align: center;
}

.button__bar li {
  list-style: none;
  cursor: pointer;
  display: inline-block;
  margin: 0 3px;
  padding: 0;
}

.button__bar li button {
  border: none;
  background: rgba(68, 68, 68, 0.3);
  color: transparent;
  cursor: pointer;
  display: block;
  height: 15px;
  width: 15px;
  border-radius: 7.5px;
}

.button__bar li.slick-active button {
  background: rgba(19, 18, 18, 0.7);
}

.button__bar li button:hover,
.button__bar li button:focus {
  background: rgba(19, 18, 18, 0.7);
  outline: 0;
}

.slick-slide img {
  /* border: 5px solid #fff; */
  display: block;
  margin: auto;
  object-fit: contain;
}

/*.divSlide{
  margin-top: 20px;
}*/

@media (max-width: 500px) {
  .slide {
   
  }
  .button__bar {
    width: 100px;
  }
  .button__bar li button {
    width: 10px;
    height: 10px;
  }
  .slick-slide img {
    object-fit: fill;
  }
}
