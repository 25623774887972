@import url("https://fonts.googleapis.com/css2?family=Spartan:wght@400;500&display=swap");

:root {
  --secondary: #bda388;

  --opacity-hover: 0.8;
  --shadow-hover: 0 0 10px #0606067a;
  --shadow: 0 0 10px rgb(6 6 6 / 10%);
  --primary: #ffffff;
  --background: #fafafa;
  --gray: #f5f5f5;

  font-family: Spartan, sans-serif;
  
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: Spartan, sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
