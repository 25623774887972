@import url(https://fonts.googleapis.com/css2?family=Spartan:wght@400;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Spartan:wght@400;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Spartan:wght@400;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Spartan:wght@400;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Spartan:wght@400;500&display=swap);
:root {
  --secondary: #bda388;

  --opacity-hover: 0.8;
  --shadow-hover: 0 0 10px #0606067a;
  --shadow: 0 0 10px rgb(6 6 6 / 10%);
  --primary: #ffffff;
  --background: #fafafa;
  --gray: #f5f5f5;

  font-family: Spartan, sans-serif;
  
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: Spartan, sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

nav {
  width: 100%;
  position: fixed;
  /*top: 0px;
  left: 0px;*/
  height: 60px;
  display: flex;
  align-items: center;
  z-index: 1;
  background-color: #fafafa;
  box-sizing: border-box;
  /*padding: 12px 36px;*/
  border-bottom: whitesmoke 1px solid;
  /*justify-content: center;*/
  grid-gap: 15%;
  gap: 15%;
  flex-direction: row;
  text-decoration: none;
  padding-left: 10rem;
}
.img_header {
  width: 120px;
}
.img_header_calendar{
  width: 40px;
  color: black;
 
}

.linkTitle{
  text-decoration: none;
  color: black;
  font-size: 12px;
}

@media (max-width: 500px) {
  

  nav {
    grid-gap: 23%;
    gap: 23%;
    padding-left: 0rem;
  }

  .img_header_calendar{
    width: 28px;
  }
  .img_header_menu{
    width: 140px;
  }
  .menu{
    
  }
  .menuIcon{
    padding-top: 1.5rem;
  padding-bottom: 1rem;
  }
  .dividerStyle{
    width: auto;
    margin-left: 10px !important;
    margin-right: 10px !important;
  }
  .linkTitle{
    padding-top: 1rem;
    padding-bottom: 1rem;
    font-size: 14px;
    font-weight: 400;
  }
}
.slide {
  width: 100% !important;
  background-size: cover !important;
  background-position: center !important;
 
  cursor: pointer;
}

.event-img {
  width: calc(100% + 30px);
  /* margin: -15px; */
  margin-bottom: 0px;
  height: 300px;
  background-size: cover;
  background-position: center;
  border-radius: 8px;
}

/*.constainer-slides {
  margin-top: 5vh;
  /* font-family: sans-serif; 
}*/

.container-center-btn {
}

.slider-arrow {
  position: absolute;
  height: 100%;
  width: 100%;
}
.arrow-btn {
  top: 45%;
  z-index: 1;
}
.next {
  float: right;
}

.button__bar {
  display: inline-block;
  vertical-align: middle;
  margin: auto;
  padding: 0;
  background: rgba(255, 255, 255, 0.3);
  border-radius: 40px;
  width: 100%;
  text-align: center;
}

.button__bar li {
  list-style: none;
  cursor: pointer;
  display: inline-block;
  margin: 0 3px;
  padding: 0;
}

.button__bar li button {
  border: none;
  background: rgba(68, 68, 68, 0.3);
  color: transparent;
  cursor: pointer;
  display: block;
  height: 15px;
  width: 15px;
  border-radius: 7.5px;
}

.button__bar li.slick-active button {
  background: rgba(19, 18, 18, 0.7);
}

.button__bar li button:hover,
.button__bar li button:focus {
  background: rgba(19, 18, 18, 0.7);
  outline: 0;
}

.slick-slide img {
  /* border: 5px solid #fff; */
  display: block;
  margin: auto;
  object-fit: contain;
}

/*.divSlide{
  margin-top: 20px;
}*/

@media (max-width: 500px) {
  .slide {
   
  }
  .button__bar {
    width: 100px;
  }
  .button__bar li button {
    width: 10px;
    height: 10px;
  }
  .slick-slide img {
    object-fit: fill;
  }
}




@media (max-width: 600px) {
  .fiCardContentR {
  
    height: 40vw !important;
    justify-items: center;
    align-items: center;
 
  }

  /*.fiCardContentR:hover {
    background: gba(0, 0, 0, 0.24);
    width: 80vw !important;
    height: 10vh !important;
  }*/
}

.conteinerMain {
  background: #ffffff;
}

.containerCard {
  margin: 3vw 15vw 3vw 15vw;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 10px;
  column-gap: 10px;
  grid-row-gap: 10px;
  row-gap: 10px;
  
}

.card {
  background: #ffffff;
  border: solid 1px black;
  border-radius: 10px;
  display: inline-block;
  transition: all 0.25s linear;
 padding: 10px;
  margin: 2vh;
  display: grid;
  grid-template-columns: 1fr 1fr;
  /* height: 35vh; */
}

.card:hover {
  box-shadow: -1px 10px 29px 0px rgba(39, 39, 41, 0.8);
}

.leftSideRows {
  display: flex;
  justify-content: center;
  align-items: center;

}

.leftStyle {
  /*width: 45vh;
  height: 30vh;*/
  display: flex;
}

.imageEvent {
  width: 100%;
  
  border-radius: 7px;
}

.rightSideRows {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto auto;
  /*padding-left: 2rem;
  padding-right: 2rem;*/
}
.containerBrands {
  display: grid;
  grid-template-columns: auto auto auto;
  /*column-gap: 1vh;
  row-gap: 1vh;
  margin-top: 2vh;*/
  justify-content: center;
  align-content: center;
  padding-left: 5px;
}

.upSide {
  /* width: 40vh;
  height: 18vh; */
  /* margin: 1vh auto; */
  align-content: center;
}
.imageBrand {
  width: 100%;
  /*height: 80%;
  object-fit: contain;*/
}

.downSide {
  /*margin: 2vh auto;
  justify-content: center;*/
  display: flex;
  flex-direction: column;
  align-items: center;
 /* padding: 0 0.5em 0 0.5em;*/
  justify-content: space-evenly;
  padding-left: 0.8rem;
  padding-right: 0.8rem;
}
.tittle {
  font-size: 1.5vw !important;
  font-weight: 500;
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: center;
  max-width: 15ch;
}
.description {
  font-size: 1vw;
  font-weight: 300;
  text-align: center;
  margin: 1px;
  min-height: 2em;
}

.button {
  padding: 5px 10px 2px 10px;
  background-color: black;
  color: white;
  cursor: pointer;
  border-radius: 15px;
  border: solid black;
  text-align: center;
  align-items: center;
  display: flex;
  
}


hr{

  width: 50%;
  background-color: black;
}

@media (max-width: 500px) {
  .containerCard {
    grid-template-columns: 100vw;
    margin: 0;
    margin-top: 2vh;
    margin-bottom: 2vh;
    grid-row-gap: 20px;
    row-gap: 20px;
  }
  .card {
    grid-template-columns: 1fr 1fr;
    box-shadow: 0px 2px 5px 2px rgba(59, 57, 57, 0.4);
   margin: 0vh 2vh 0vh 2vh;
  }
  .leftStyle {
    width: 100%;
    display: flex;
  }
  .upSide{
    justify-content: center;
  display: flex;
  }

  .imageEvent {
    border-radius: 7px 0px 0px 7px;
  }
  .button{
    width: auto !important;
    height: 3vh !important;
    font-size: 3vw !important;
  }
  .rightSideRows {
    grid-template-rows: auto auto auto;

  }
  .downSide {
    justify-content: space-evenly;
    margin-top: 1vh;
  }
  .containerBrands{
    margin: 0;
  }
  .imageBrand{
    width: 70%;
  }
  .description {
    font-size: 2.5vw;
    font-weight: 400;
    text-align: center;
    margin: 5px;
    min-height: 4em;
  }
  .tittle {
    font-size: 2vh !important;
    font-weight: 550;

  }

  hr{
    height: 0px;
   
  }
}

/*.homeDiv{
  overflow: hidden;
}*/

.container-btn {
  display: grid;
  grid-template-columns: 40% 40%;
  margin-top: 3vh;
  margin-bottom: 1vh;
  justify-items: center;
  justify-content: center;
  grid-column-gap: 30px;
  column-gap: 30px;
}
.cardButtonNav{
  box-shadow: 0px !important;
}
.bannerHomeImg{
  width: 100%;
  height: auto; /* Mantener la proporción de la imagen */
display: block;
padding-top: 60px;
  padding-bottom: 5px;
}
.divButtonVer{
  display: flex;
  justify-content: center;
 align-content: center;
}
.link{
  text-decoration: none;
}

@media (max-width: 500px) {
  .container-btn {
    display: block;
    margin-top: 10px;
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .bannerHomeImg{
    width: 100%;
    height: auto; /* Mantener la proporción de la imagen */
  display: block;
  padding-top: 60px;
  padding-bottom: 2px;
  }

  .button {
    width: 15vh;
    height: 4.5vh;
    background-color: black;
    color: #ffffff;
    font-size: 1.5vh;
    cursor: pointer;
    border-radius: 20px;
    text-align: center;
  }
  
  .button:hover {
    background-color: rgb(46, 46, 46);
    color: white;
  }
  
  .button:active {
    background-color: rgb(46, 46, 46);
  
    transform: translateY(4px);
  }
  .divButtonVer{
    display: flex;
    justify-content: center;
   align-content: center;
   margin-top: 2rem;
  }

}

.title{
    text-align: center;
    margin-top: 5rem;
    margin-bottom: -2rem;
}

.title-text{
    font-weight: 800;
}
@media (max-width: 500px) {
    .title{
        
        margin-top: 1.5rem;
        margin-bottom: 1rem;
    }
    
    .title-text{
        font-size: 5vw;
    }
}
.footerBox{
    margin-top: 20px;
    width: 100%;
   
    position: relative;
    bottom: 0;
}
.iconImage{
    width: 35px;
    height: 35px;
}
.iconButton{
    padding: 0px !important;
}
.hrFooter{
    width: 100%;
    margin: 0px !important;
    padding: 0px !important;
    border: none;
    height: 1px;
    margin: 0;
    flex-shrink: 0;
    background-color: rgba(0, 0, 0, 0.444);
}
.footerText{
    color: rgb(174, 174, 174);
    text-align: center;
    padding-top: 20px;
    font-size: 12px !important;
}
.boxIcon{
    justify-content: space-between;
    grid-gap: 20px;
    gap: 20px;
    padding: 0;
}

.iconButtonFlecha{
    position: fixed;
    bottom: 10px;
    left: 30px;
    padding: 20px;
   
    border-radius: 20px;
    z-index: 1000;
    text-align: left !important;
}

.iconButtonFlecha :hover {
   
}

.iconImageFlecha{
    filter: invert(100%);
    width: 20px;
    height: 20px;
    padding: 5px;
    background-color: rgb(66, 92, 119);
    border-radius: 30px;
    border: 0px
}

.hrSpce{
    max-width: 35%;
    flex-basis: 35%;
}

.bodySpce{
    max-width: 30%;
    flex-basis: 30%;
}

@media (max-width: 500px) {
    .footerBox{
        margin-top: 20px;
        width: 100%;
        
        
        position: relative;
        bottom: 0;
    
    }
    .iconImage{
        width: 25px;
        height: 25px;
    }
    .iconButton{
        padding: 0px !important;
    }
    .hrFooter{
        width: 100%;
        margin: 0px !important;
        padding: 0px !important;
        border: none;
        height: 1px;
        margin: 0;
        flex-shrink: 0;
        background-color: rgba(0, 0, 0, 0.444);
    }
    .footerText{
        color: rgb(174, 174, 174);
        text-align: center;
        padding-top: 20px;
        font-size: 8px !important;
    }
    .boxIcon{
        justify-content: space-between;
        grid-gap: 0px;
        gap: 0px;
    }

    .iconButtonFlecha{
        position: fixed;
        bottom: 10px;
        left: 30px;
        padding: 20px;
        border-radius: 20px;
        z-index: 1000;
        text-align: left !important;
    }

    .hrSpce{
        max-width:16.666667%;
        flex-basis: 16.666667%;
    }
    
    .bodySpce{
        max-width: 66.666667%;
        flex-basis: 66.666667%;
    }
}
html *{
  font-family: Spartan, sans-serif;
}
.calendar-day {
  color: black;
  width: 35px;
  height: 35px;
  padding: 0;
  font-size: 10px;
  text-align: center;
  font-weight: normal;
  line-height: 35px;
  border: 0;
  background-color: white;
}
.calendar-weekday {
  background-color: #D8D6D6;
  color: #4a4a4a;
}
.calendar-day-eventin {
  color: black;
  /*border: 1px solid white;*/
  width: 35px;
  height: 35px;
  padding: 0;
  font-size: 10px;
  text-align: center;
  font-weight: normal;
  line-height: 35px;
  background-color: lightgreen;
  cursor: pointer;
}
.calendar-day-eventin:hover {
  background-color: #a6a6a6b8;
}
.calendar-day-select {
  color: white;
  /*border: 1px solid white;*/
  width: 35px;
  height: 35px;
  padding: 0;
  font-size: 10px;
  text-align: center;
  font-weight: normal;
  line-height: 35px;
  background-color: #a5a5a5;
  cursor: pointer;
}

.calendar-day-eventout {
  color: #00000080;
  width: 35px;
  height: 35px;
  padding: 0;
  font-size: 10px;
  text-align: center;
  font-weight: normal;
  line-height: 35px;
  border: 0;
  background-color: white;
}

.tail-datetime-calendar {
  width: 245px;
  height: auto;
  /* margin-top: 4vh!important; */
  margin: 1rem 0rem;
  padding: 0;
  display: block;
  overflow: hidden;
  /* position: absolute; */
  border-collapse: separate;
  font-family: "Open Sans", Calibri, Arial, sans-serif;
  background-color: #ffffff;
  /* box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3125); */
  /* -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3125); */
  border-radius: 3px;
  -webkit-border-radius: 3px;
}
.tail-datetime-calendar:after {
  clear: both;
  content: "";
  display: block;
  font-size: 0;
  visibility: hidden;
}
.tail-datetime-calendar.calendar-static {
  margin-left: auto;
  margin-right: auto;
}
.tail-datetime-calendar .calendar-navi {
  width: 100%;
  margin: 0;
  padding: 0;
  display: table;
  border-spacing: 0;
  border-collapse: separate;
  background-color: white;
  border-radius: 3px 3px 0 0;
  -webkit-border-radius: 3px 3px 0 0;
}
.tail-datetime-calendar .calendar-navi span {
  cursor: pointer;
  color: #3d4548;
  margin: 0;
  padding: 0;
  display: table-cell;
  font-size: 14px;
  text-align: center;
  line-height: 30px;
  /*text-shadow: -1px -1px 0 rgba(0, 0, 0, 0.15);*/
  background-repeat: no-repeat;
  background-position: center center;
}
.tail-datetime-calendar .calendar-navi span:first-child,
.tail-datetime-calendar .calendar-navi span:last-child {
  width: 35px;
  padding: 0 0 5px 0;
  font-size: 22px;
}
/* .tail-datetime-calendar .calendar-navi span:hover {
  background-color: rgba(0, 0, 0, 0.15);
} */
.tail-datetime-calendar .calendar-navi span.button-prev {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC\
    9zdmciIHdpZHRoPSI2IiBoZWlnaHQ9IjE2IiB2aWV3Qm94PSIwIDAgNiAxNiI+PHBhdGggZmlsbD0iI2ZmZmZmZiIgZD0iT\
    TYgMkwwIDhsNiA2VjJ6Ii8+PC9zdmc+");
}
.tail-datetime-calendar .calendar-navi span.button-next {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC\
    9zdmciIHdpZHRoPSI2IiBoZWlnaHQ9IjE2IiB2aWV3Qm94PSIwIDAgNiAxNiI+PHBhdGggZmlsbD0iI2ZmZmZmZiIgZD0iT\
    TAgMTRsNi02LTYtNnYxMnoiLz48L3N2Zz4=");
}
.tail-datetime-calendar .calendar-navi span.button-check {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC\
    9zdmciIHdpZHRoPSIxMiIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDEyIDE2Ij48cGF0aCBmaWxsPSIjZmZmZmZmIiBkP\
    SJNMTIgNWwtOCA4LTQtNCAxLjUtMS41TDQgMTBsNi41LTYuNUwxMiA1eiIvPjwvc3ZnPg==");
}
.tail-datetime-calendar .calendar-navi span.button-close {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC\
    9zdmciIHdpZHRoPSIxMiIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDEyIDE2Ij48cGF0aCBmaWxsPSIjZmZmZmZmIiBkP\
    SJNNy40OCA4bDMuNzUgMy43NS0xLjQ4IDEuNDhMNiA5LjQ4bC0zLjc1IDMuNzUtMS40OC0xLjQ4TDQuNTIgOCAuNzcgNC4y\
    NWwxLjQ4LTEuNDhMNiA2LjUybDMuNzUtMy43NSAxLjQ4IDEuNDhMNy40OCA4eiIvPjwvc3ZnPg==");
}
.tail-datetime-calendar .calendar-date {
  margin: 0;
  padding: 0;
  display: block;
}
.tail-datetime-calendar .calendar-date table {
  width: 100%;
  margin: 0;
  padding: 0;
  border-spacing: 0;
  border-collapse: collapse;
}

/* .tail-datetime-calendar .calendar-date table thead tr > *, */
/* .tail-datetime-calendar .calendar-date table tbody tr > * {
  color: black;
  width: 35px;
  height: 35px;
  padding: 0;
  font-size: 12px;
  text-align: center;
  font-weight: normal;
  line-height: 35px;
  border: 0;
  background-color: antiquewhite;
}
.tail-datetime-calendar .calendar-date table tbody tr > * {
  color: #334455;
  cursor: pointer;
  position: relative;
  font-size: 14px;
  background-color: #ffffff;
}
.tail-datetime-calendar .calendar-date table tbody tr > * > span {
  color: inherit;
  z-index: 10;
  position: relative;
} */
/* Select a Day */
.tail-datetime-calendar .calendar-date table tbody tr > *::after {
  top: 3px;
  left: 3px;
  width: 29px;
  height: 29px;
  content: "";
  display: block;
  position: absolute;
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  border-radius: 50%;
  -webkit-border-radius: 50%;
}
/* .tail-datetime-calendar .calendar-date table tbody tr > *.today {
  color: #3296c8;
}
.tail-datetime-calendar .calendar-date table tbody tr > *.today:after {
  border-color: #3296c8;
}
.tail-datetime-calendar .calendar-date table tbody tr > *:hover {
  color: #cd283c;
}
.tail-datetime-calendar .calendar-date table tbody tr > *:hover:after {
  border-color: #cd283c;
} */
.tail-datetime-calendar .calendar-date table tbody tr > *.empty,
.tail-datetime-calendar .calendar-date table tbody tr > *.disable {
  color: #8899aa;
  cursor: not-allowed;
  background-color: #efefef;
}
.tail-datetime-calendar .calendar-date table tbody tr > *.empty:hover:after,
.tail-datetime-calendar .calendar-date table tbody tr > *.disable:hover:after {
  content: "";
  display: none;
}
.tail-datetime-calendar .calendar-date table tbody tr > *.current span {
  color: #fff;
}
.tail-datetime-calendar .calendar-date table tbody tr > *.current:after {
  border-color: #3296c8;
  background-color: #3296c8;
}
/* Select A Month */
.tail-datetime-calendar .calendar-date table.calendar-month tbody tr > * {
  width: 81.66666666666667px;
  padding: 5px;
  line-height: 25px;
}
.tail-datetime-calendar .calendar-date table.calendar-month tbody tr > * span {
  display: block;
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  border-radius: 3px;
  -webkit-border-radius: 3px;
}
.tail-datetime-calendar
  .calendar-date
  table.calendar-month
  tbody
  tr
  > *:hover
  span {
  border-color: #d0d0d0;
  box-shadow: 0 1px 0 0 #efefef;
  -webkit-box-shadow: 0 1px 0 0 #efefef;
}
/* Select a Time */
.tail-datetime-calendar .calendar-time {
  width: 100%;
  margin: 0;
  padding: 0;
  display: table;
  text-align: center;
  background-color: #ffffff;
  border-radius: 0 0 3px 3px;
  -webkit-border-radius: 0 0 3px 3px;
}
.tail-datetime-calendar .calendar-date + .calendar-time {
  border-top: 1px solid #d0d0d0;
  background-color: #f8f8f8;
}
.tail-datetime-calendar .calendar-time .calendar-field {
  width: 33.3333333333%;
  padding: 10px 0;
  display: table-cell;
  position: relative;
  text-align: center;
  vertical-align: top;
}
.tail-datetime-calendar .calendar-time .calendar-field:first-child {
  text-align: right;
}
.tail-datetime-calendar .calendar-time .calendar-field:first-child:after {
  top: 12px;
  right: -10px;
  content: ":";
  position: absolute;
}
.tail-datetime-calendar .calendar-time .calendar-field:last-child {
  text-align: left;
}
.tail-datetime-calendar .calendar-time .calendar-field:last-child:after {
  top: 12px;
  left: -10px;
  content: ":";
  position: absolute;
}
.tail-datetime-calendar .calendar-time .calendar-field input[type="number"] {
  width: 100%;
  max-width: 50px;
  margin: 0;
  padding: 3px 4px;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  border-width: 1px;
  border-style: solid;
  border-color: #d0d0d0;
  background-color: #ffffff;
  border-radius: 3px;
  -webkit-border-radius: 3px;
}
.tail-datetime-calendar
  .calendar-time
  .calendar-field
  input[type="number"]:hover {
  border-color: #a0a0a0;
}
.tail-datetime-calendar
  .calendar-time
  .calendar-field
  input[type="number"]:focus {
  border-color: #3296c8;
}
.tail-datetime-calendar .calendar-time .calendar-field label {
  color: #778899;
  margin: 5px 0 0 0;
  padding: 0;
  display: block;
  font-size: 12px;
  line-height: 12px;
}
/* @end tail.DateTime */

.calendar-day {
  color: black;
  width: 35px;
  height: 35px;
  padding: 0;
  font-size: 14px !important;
  text-align: center;
  font-weight: normal;
  line-height: 35px;
  border: 0;
  background-color: white;
}
.calendar-weekday {
  background-color: #D8D6D6;
  color: #4a4a4a;
}
.calendar-day-eventin {
  color: black;
  /*border: 1px solid white;*/
  width: 35px;
  height: 35px;
  padding: 0;
  font-size: 14px !important;
  text-align: center;
  font-weight: normal;
  line-height: 35px;
  background-color: lightgreen;
  cursor: pointer;
}
.calendar-day-select {
  color: white;
  /*border: 1px solid white;*/
  width: 35px;
  height: 35px;
  padding: 0;
  font-size: 14px !important;
  text-align: center;
  font-weight: normal;
  line-height: 35px;
  background-color: #a5a5a5;
  cursor: pointer;
}

.calendar-day-eventout {
  color: #00000080;
  width: 35px;
  height: 35px;
  padding: 0;
  font-size: 14px !important;
  text-align: center;
  font-weight: normal;
  line-height: 35px;
  border: 0;
  background-color: white;
}

.tail-datetime-calendar {
  width: auto !important;
  height: auto !important;
  /* margin-top: 4vh!important; */
  margin: 1rem 0rem;
  padding: 0;
  display: block;
  overflow: hidden;
  /* position: absolute; */
  border-collapse: separate;
  font-family: "Open Sans", Calibri, Arial, sans-serif;
  background-color: #ffffff;
  /* box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3125); */
  /* -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3125); */
  border-radius: 15px !important;
  -webkit-border-radius: 3px;
}
.tail-datetime-calendar:after {
  clear: both;
  content: "";
  display: block;
  font-size: 0;
  visibility: hidden;
}
.tail-datetime-calendar.calendar-static {
  margin-left: auto;
  margin-right: auto;
}
.tail-datetime-calendar .calendar-navi {
  width: 100%;
  margin: 0;
  padding: 0;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  display: table;
  border-spacing: 0;
  border-collapse: separate;
  background-color: black;
  border-radius: 3px 3px 0 0;
  -webkit-border-radius: 3px 3px 0 0;
}
.tail-datetime-calendar .calendar-navi span {
  cursor: pointer;
  color: white;
  margin: 0;
  padding: 0;
  display: table-cell;
  font-size: 16px !important;
  text-align: center;
  line-height: 30px;
  /*text-shadow: -1px -1px 0 rgba(0, 0, 0, 0.15);*/
  background-repeat: no-repeat;
  background-position: center center;
}
.tail-datetime-calendar .calendar-navi span:first-child,
.tail-datetime-calendar .calendar-navi span:last-child {
  width: auto !important;
  padding: 0 !important;
  font-size: 18px !important;
}
/* .tail-datetime-calendar .calendar-navi span:hover {
  background-color: rgba(0, 0, 0, 0.15);
} */
.tail-datetime-calendar .calendar-navi span.button-prev {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC\
    9zdmciIHdpZHRoPSI2IiBoZWlnaHQ9IjE2IiB2aWV3Qm94PSIwIDAgNiAxNiI+PHBhdGggZmlsbD0iI2ZmZmZmZiIgZD0iT\
    TYgMkwwIDhsNiA2VjJ6Ii8+PC9zdmc+");
}
.tail-datetime-calendar .calendar-navi span.button-next {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC\
    9zdmciIHdpZHRoPSI2IiBoZWlnaHQ9IjE2IiB2aWV3Qm94PSIwIDAgNiAxNiI+PHBhdGggZmlsbD0iI2ZmZmZmZiIgZD0iT\
    TAgMTRsNi02LTYtNnYxMnoiLz48L3N2Zz4=");
}
.tail-datetime-calendar .calendar-navi span.button-check {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC\
    9zdmciIHdpZHRoPSIxMiIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDEyIDE2Ij48cGF0aCBmaWxsPSIjZmZmZmZmIiBkP\
    SJNMTIgNWwtOCA4LTQtNCAxLjUtMS41TDQgMTBsNi41LTYuNUwxMiA1eiIvPjwvc3ZnPg==");
}
.tail-datetime-calendar .calendar-navi span.button-close {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC\
    9zdmciIHdpZHRoPSIxMiIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDEyIDE2Ij48cGF0aCBmaWxsPSIjZmZmZmZmIiBkP\
    SJNNy40OCA4bDMuNzUgMy43NS0xLjQ4IDEuNDhMNiA5LjQ4bC0zLjc1IDMuNzUtMS40OC0xLjQ4TDQuNTIgOCAuNzcgNC4y\
    NWwxLjQ4LTEuNDhMNiA2LjUybDMuNzUtMy43NSAxLjQ4IDEuNDhMNy40OCA4eiIvPjwvc3ZnPg==");
}
.tail-datetime-calendar .calendar-date {
  margin: 0;
  padding: 0;
  display: block;
}
.tail-datetime-calendar .calendar-date table {
  width: 100%;
  margin: 0;
  padding: 0;
  border-spacing: 0;
  border-collapse: collapse;
}

/* .tail-datetime-calendar .calendar-date table thead tr > *, */
/* .tail-datetime-calendar .calendar-date table tbody tr > * {
  color: black;
  width: 35px;
  height: 35px;
  padding: 0;
  font-size: 12px;
  text-align: center;
  font-weight: normal;
  line-height: 35px;
  border: 0;
  background-color: antiquewhite;
}
.tail-datetime-calendar .calendar-date table tbody tr > * {
  color: #334455;
  cursor: pointer;
  position: relative;
  font-size: 14px;
  background-color: #ffffff;
}
.tail-datetime-calendar .calendar-date table tbody tr > * > span {
  color: inherit;
  z-index: 10;
  position: relative;
} */
/* Select a Day */
.tail-datetime-calendar .calendar-date table tbody tr > *::after {
  top: 3px;
  left: 3px;
  width: 29px;
  height: 29px;
  content: "";
  display: block;
  position: absolute;
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  border-radius: 50%;
  -webkit-border-radius: 50%;
}
/* .tail-datetime-calendar .calendar-date table tbody tr > *.today {
  color: #3296c8;
}
.tail-datetime-calendar .calendar-date table tbody tr > *.today:after {
  border-color: #3296c8;
}
.tail-datetime-calendar .calendar-date table tbody tr > *:hover {
  color: #cd283c;
}
.tail-datetime-calendar .calendar-date table tbody tr > *:hover:after {
  border-color: #cd283c;
} */
.tail-datetime-calendar .calendar-date table tbody tr > *.empty,
.tail-datetime-calendar .calendar-date table tbody tr > *.disable {
  color: #8899aa;
  cursor: not-allowed;
  background-color: white;
}
.tail-datetime-calendar .calendar-date table tbody tr > *.empty:hover:after,
.tail-datetime-calendar .calendar-date table tbody tr > *.disable:hover:after {
  content: "";
  display: none;
}
.tail-datetime-calendar .calendar-date table tbody tr > *.current span {
  color: #fff;
}
.tail-datetime-calendar .calendar-date table tbody tr > *.current:after {
  border-color: #3296c8;
  background-color: #3296c8;
}
/* Select A Month */
.tail-datetime-calendar .calendar-date table.calendar-month tbody tr > * {
  width: 81.66666666666667px;
  padding: 5px;
  line-height: 25px;
}
.tail-datetime-calendar .calendar-date table.calendar-month tbody tr > * span {
  display: block;
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  border-radius: 3px;
  -webkit-border-radius: 3px;
}
.tail-datetime-calendar
  .calendar-date
  table.calendar-month
  tbody
  tr
  > *:hover
  span {
  border-color: #d0d0d0;
  box-shadow: 0 1px 0 0 #efefef;
  -webkit-box-shadow: 0 1px 0 0 #efefef;
}
/* Select a Time */
.tail-datetime-calendar .calendar-time {
  width: 100%;
  margin: 0;
  padding: 0;
  display: table;
  text-align: center;
  background-color: #ffffff;
  border-radius: 0 0 3px 3px;
  -webkit-border-radius: 0 0 3px 3px;
}
.tail-datetime-calendar .calendar-date + .calendar-time {
  border-top: 1px solid #d0d0d0;
  background-color: #f8f8f8;
}
.tail-datetime-calendar .calendar-time .calendar-field {
  width: 33.3333333333%;
  padding: 10px 0;
  display: table-cell;
  position: relative;
  text-align: center;
  vertical-align: top;
}
.tail-datetime-calendar .calendar-time .calendar-field:first-child {
  text-align: right;
}
.tail-datetime-calendar .calendar-time .calendar-field:first-child:after {
  top: 12px;
  right: -10px;
  content: ":";
  position: absolute;
}
.tail-datetime-calendar .calendar-time .calendar-field:last-child {
  text-align: left;
}
.tail-datetime-calendar .calendar-time .calendar-field:last-child:after {
  top: 12px;
  left: -10px;
  content: ":";
  position: absolute;
}
.tail-datetime-calendar .calendar-time .calendar-field input[type="number"] {
  width: 100%;
  max-width: 50px;
  margin: 0;
  padding: 3px 4px;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  border-width: 1px;
  border-style: solid;
  border-color: #d0d0d0;
  background-color: #ffffff;
  border-radius: 3px;
  -webkit-border-radius: 3px;
}
.tail-datetime-calendar
  .calendar-time
  .calendar-field
  input[type="number"]:hover {
  border-color: #a0a0a0;
}
.tail-datetime-calendar
  .calendar-time
  .calendar-field
  input[type="number"]:focus {
  border-color: #3296c8;
}
.tail-datetime-calendar .calendar-time .calendar-field label {
  color: #778899;
  margin: 5px 0 0 0;
  padding: 0;
  display: block;
  font-size: 12px;
  line-height: 12px;
}
/* @end tail.DateTime */



.react-container {
  margin-top: 80px;
  display: flex;
  flex: 2 1;
  flex-direction: column;
}

.container-center-image {
  display: flex;
  flex: 1 1;
  justify-content: center;
  max-height: 60vh;
}

.center-image {
  margin: 2rem 0;
  /* min-width: 30vw; */
  width: 60vw;
  height: 45vh;
}
.imgbanner{
  object-fit: contain;
  width: 100%;
}

select {
  font-family: Spartan, sans-serif;
  font-size: 18px !important;
  height: 3rem;
  border-radius: 3rem !important;
}



option {
  font-size: 18px !important;
}

.buttonFormAgenda {
 
  height: 2.5rem !important;
  background-color: black !important;
  color: white !important;
  margin: 4vh auto;
  font-size: 18px !important;
  border-radius: 2rem !important;
}

.containerButton {
  display: flex;
  order: 4;
 
  margin-top: 2rem;
  align-self: center;
}
.eventTitle{
  text-align: center;
  font-size: larger;
  font-weight: bold;
}

.eventDescription{
 text-align: center;
  font-size: large;
  width: 50%;
  align-self: center;

}
.calendar-container {
  /* flex: 1; */
}

.divCalendar {
  padding: 0.5rem;
}


.calendar {
  display: flex;
  flex: 8 1;
  flex-direction: row;
  justify-content: center;   
  background-color: #D8D6D6;
}



.zone {
  
  position: relative;
  padding: 12px 19px;
  cursor: pointer;
}

.zone::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 50%; /* Ancho del borde más corto */
  border-bottom: 2px solid #000; /* Estilo del borde */
  display: none; /* El borde está oculto por defecto */
}

.zone.active::after {
  display: block; /* Muestra el borde solo cuando "active" es true */
}

.form-calendar {
  display: flex;
  flex: 3 1;
  flex-direction: row;
  justify-content: center;
  padding: 4vh 20vw;
  background-color: #EFEFEF;
  
}

.input-container {
  /* height: 77vh; */
  display: flex;
  /*justify-content: center;*/
  flex-direction: column;
  max-width: 80%;
  justify-content: space-around;
  font-size: 20px;
}
.div_form {
  min-width: 50%;
}

.grid-container {
  display: flex;
  flex: 1 1;
  flex-direction: column;
}

.grid-input {
  display: flex;
  flex-direction: column;
  padding: 0.5em;
}

.input {
  border-radius: 3rem !important;
  height: 3rem !important;
  border: none;
  font-size: 20px;
  padding-left: 1rem;
  padding-right: 1rem;
  color: black !important;
}

.label {
  font-size: 1rem !important;
  font-weight: 100 !important;
  padding: 0px 0px 5px 15px !important;
}

.order {
  flex-grow: 3;
  /*padding: 2vh 4.5vh;*/
  /*flex-flow: row wrap;*/
}

.grid-calendario {
  width: auto !important;
  /*display: grid;
  grid-template-rows: 1fr 1fr;
  /* row-gap: 10vh; 
  width: 20% !important;
  /* flex-direction: column; 
  height: 33vh;*/
  margin-bottom: 1rem;
}

.button-save {
  border-radius: 50px;
  background-color: black;
  color: white;
  font-size: 16px;
  width: 190px;
  height: 30px;
}

.container-view {
}

.grid-time {
  display: flex;
  width: auto !important;
  flex-direction: column;
  /* height: 38vh; */
 
}

.grid-item-time {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr !important;
  overflow-y: hidden !important;
  margin-bottom: 2rem !important;
}

.item-time {
  display: flex;
  /* width: 30%; */
  border-radius: 30px !important;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 16px !important;
  padding: 15px 25px !important;
  background-color: aliceblue;
  margin: 5px 8px !important;
  flex-grow: 2;
}

.item-time:hover {
  background-color: #dddddd !important;
}

/* .view-calendar {
  border-radius: 10px;
  background-color: aliceblue;
  width: 35vh;
  margin: auto;
  height: 35vh;
} */

.view-calendar {
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
  -webkit-user-select: none;
          user-select: none;
  width: 100vw;
  padding: 10px;
  padding-top: 0;
}

.labelMsg{
  
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  
  white-space: pre-line;
  line-height: 1.6em;
  text-align: center;

}

.sucursal{
  margin-bottom: 2rem;
order: 1;
}



@media (min-width: 480px) {
  .view-calendar {
    height: auto;
    width: 340px;
    max-width: 100%;
  }
}

.month-container {
  width: 100%;
  display: grid;
  -ms-grid-columns: 1fr;
  -ms-grid-rows: 1fr;
}

.container-save-btn {
  margin-top: 5vh;
  margin-top: vh;
  justify-content: center;
  display: flex;
}



@media (max-width: 500px) {
  .calendar {
    justify-content: center ;
    overflow-y: auto !important;
  }
  .zone {
    display: ruby;
    display: ruby-text;
  }
  .form-calendar {
    display: -webkit-flex;
    flex-direction: column;
    height: auto;
    padding: 0;
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  .order {
    max-width: 100vw;
  }
  .input-container {
    order: 3;
  }

  .grid-time {
    display: grid;
    order: 2;
    width: auto !important;
    height: auto !important;
    margin-top: 1rem;
    padding: 0.5rem;
  }
  .containerButton {
    align-self: center;
    width: 50%;
    margin-top: 1rem !important;
  }
  .grid-calendario {
    grid-template-rows: 1fr;
    order: 1;
    width: 100% !important;
    margin-bottom: 1rem !important;
  }
  .divCalendar {
    padding: 0.5rem;
    
  }
  .center-image {
    width: 100vw !important;
    height: 35vh !important;
    background-size: cover !important;
    margin:  0 !important;
  }
  .react-container {
    margin-top: 0 !important;
  }

  .div_form {
    min-width: 90% !important;
  }

  .grid-item-time {
    display: grid;
    grid-template-columns: 1fr 1fr  !important;
    flex-flow: row wrap;
    margin-bottom: 2rem !important;
    overflow-y: hidden !important;
    padding-left: 5px;
  }
 
  .item-time {
    height: 2vh;
    width: 30vw;
  
  }

  .eventTitle{
    font-size: small;
    margin-top: 4rem;
  }
  .eventDescription{
    width: 80%;
  }

  .container-center-image {
    max-height: 50vh;
    max-width: 99vw;
  }
  .imgbanner{
    object-fit: contain;
    width: 100%;
  }
  .imgbannermobile{
    max-width: 95vw;
  }

  .label {
    padding-left: 1rem !important;
  }
  .bannerEvent{
    margin-top: 2px;
  margin-bottom: 2px;
  }

  .sucursal{
    margin-bottom: 1rem;

  }
}


.react-container {
  margin-top: 10px !important;
  display: flex;
  flex: 2 1;
  flex-direction: column;
}
.containerTittleConsul {
  width: 43vw;
  height: 20vh;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 0 auto;
}
.container-wrap-consultants {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 2.5vh;
}
.container-text-consultant {
  display: grid;
  grid-auto-rows: 1fr;
  margin: 0 2vh;
}
.consultant {
  display: grid;
  border: 1px solid #fff0;
  border-radius: 10px;
  background-color: lightsteelblue;
  margin: 1vh;
  min-height: 3vh;
  text-align: center;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;
}

.tittleConsul {
  font-size: 4vh;
  font-weight: 600;
}

.zone {
  
  position: relative;
  padding: 12px 19px;
  
}

.zone::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 50%; /* Ancho del borde más corto */
  border-bottom: 2px solid #000; /* Estilo del borde */
  display: none; /* El borde está oculto por defecto */
}

.zone.active::after {
  display: block; /* Muestra el borde solo cuando "active" es true */
}

.divCalendar {
  padding: 0.5rem;
}

.calendar {
  display: flex;
  flex: 8 1;
  flex-direction: row;
  justify-content: center;
}

.form-calendarr {
  display: flex;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  justify-content: center;
  padding: 4vh 20vw;
  background-color: #EFEFEF;
  min-height: 60vh;
  height: 100%;
}

.input-containerr {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  justify-content: space-around;
  color: black;
}

.div_form {
  min-width: 35%;
}
.grid-container {
  display: flex;
  flex: 1 1;
  flex-direction: column;
}

.grid-input {
  display: flex;
  flex-direction: column;
  padding: 0.5em;
}

.input {
  border-radius: 5px;
  border: none;
  font-size: 20px;
  color: black !important;
}

.label {
  font-size: 12px;
  font-weight: 500;
  padding: 3px;
}

.grid-calendarr {
  display: grid;
  grid-template-rows: 5vh 0.25fr;
}

.button-save {
  border-radius: 50px;
  background-color: black;
  color: white;
  font-size: 16px;
  width: 190px;
  height: 30px;
}

.container-view {
}

.container-days {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 1vh;
  column-gap: 1vh;
  grid-template-rows: 1fr 1fr;
  grid-row-gap: 1vh;
  row-gap: 1vh;
}

.day {
  border-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  justify-content: center stretch;
  /* box-shadow: 2px 2px grey; */
  background-color: wheat;
  text-align: center;
  font-size: 13px;
  font-weight: 500;
}

.grid-time {
  display: flex;
  width: 20vw;
  flex-direction: column;
  /* height: 38vh; */
}

.grid-calendario {
  display: grid;
 /*grid-template-rows: 1fr 1fr;
  /* row-gap: 10vh; 
  width: 25vw;
  /* flex-direction: column; 
  height: 33vh;*/
  margin-bottom: 1rem;
}

.grid-item-time {
  display: grid;
  grid-template-columns: 1fr 1fr;
  overflow-y: scroll;
}

.item-time {
  display: flex;
  /* width: 30%; */
  border-radius: 6px;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 14px;
  padding: 7px 15px;
  background-color: white;
  margin: 3px;
  flex-grow: 2;
}

.item-time:hover {
  background-color: #dddddd !important;
}

/* .view-calendar {
  border-radius: 10px;
  background-color: aliceblue;
  width: 35vh;
  margin: auto;
  height: 35vh;
} */

.view-calendar {
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
  -webkit-user-select: none;
          user-select: none;
  width: 100vw;
  padding: 10px;
  padding-top: 0;
}

@media (min-width: 480px) {
  .view-calendar {
    height: auto;
    width: 340px;
    max-width: 100%;
  }
}

.month-container {
  width: 100%;
  display: grid;
  -ms-grid-columns: 1fr;
  -ms-grid-rows: 1fr;
}

.container-save-btn {
  margin-top: 5vh;
  margin-top: vh;
  justify-content: center;
  display: flex;
}

.buttonFormAgenda {
  width: 95%;
  height: 5vh;
  background-color: black;
  color: white;
  margin: 4vh auto;
  border-radius: 10px;
}

.containerButtonConsult {
  display: flex;
  order: 4;
 
  margin-top: 2rem;
  align-self: center;
 
}

@media (max-width: 500px) {
  .calendar {
    justify-content: left;
    overflow-y: auto !important;
  }
  .zone {
    display: ruby;
    display: ruby-text;
  }
  .form-calendarr {
    display: -webkit-flex;
    flex-direction: column;
    height: auto;
    padding: 0;
    padding-bottom: 2rem;
    padding-top: 2rem;
  }
  .order {
    max-width: 100vw;
    flex-flow: row wrap;
  }
  .input-container {
    order: 3;
  }

  .grid-time {
    display: grid;
    order: 2;
    width: auto !important;
    margin-top: 1rem;
  }

  .grid-item-time {
    display: grid;
    grid-template-columns: 1fr 1fr  !important;
    flex-flow: row wrap;
    margin-bottom: 2rem !important;
    overflow-y: hidden !important;
    padding-left: 5px;
  }

  .containerButtonConsult {
    align-self: center;
    width: 50%;
    margin-top: 1rem !important;
  }
  .grid-calendario {
    grid-template-rows: 1fr;
    height: auto;
    order: 1;
    margin-bottom: 1rem !important;
  }
  .divCalendar {
    padding: 0.5rem;
   
  }
  .containerTittle {
    width: 100vw !important;
    height: 35vh !important;
    background-size: cover !important;
    margin: 5rem 0 2rem 0 !important;
  }
  .react-container {
    margin-top: 0 !important;
  }

  .tittleConsul {
    font-size: 2vh;
   
  }

  .containerTittleConsul {
    width: 80vw !important;
    height: 10vh;
  }

  .container-text-consultant{
    margin: 0 5vh;
  text-align: center;
  font-size: 16px;
  }
}

.calendar-day {
  color: black;
  width: 35px;
  height: 35px;
  padding: 0;
  font-size: 10px;
  text-align: center;
  font-weight: normal;
  line-height: 35px;
  border: 0;
  background-color: white;
}
.calendar-weekday {
  background-color: #D8D6D6;
  color: #4a4a4a;
}
.calendar-day-eventin {
  color: black;
  /*border: 1px solid white;*/
  width: 35px;
  height: 35px;
  padding: 0;
  font-size: 10px;
  text-align: center;
  font-weight: normal;
  line-height: 35px;
  background-color: lightgreen;
  cursor: pointer;
}

.calendar-day-eventin:hover {
  background-color: #a6a6a6b8;
}

.calendar-day-event-store {
  color: black;
  /*border: 1px solid white;*/
  width: 35px;
  height: 35px;
  padding: 0;
  font-size: 12px;
  text-align: center;
  font-weight: normal;
  line-height: 35px;
  background-color: gray;
  cursor: not-allowed;
  pointer-events: none;
}
.popup-event-store {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
}
.calendar-day-event-store:hover .popup-event-store {
  visibility: visible;
}
.calendar-day-select {
  color: white;
  /*border: 1px solid white;*/
  width: 35px;
  height: 35px;
  padding: 0;
  font-size: 10px;
  text-align: center;
  font-weight: normal;
  line-height: 35px;
  background-color: #a5a5a5;
  cursor: pointer;
}

.calendar-day-eventout {
  color: #00000080;
  width: 35px;
  height: 35px;
  padding: 0;
  font-size: 10px;
  text-align: center;
  font-weight: normal;
  line-height: 35px;
  border: 0;
  background-color: white;
}

.tail-datetime-calendar {
  width: 245px;
  height: auto;
  /* margin-top: 4vh!important; */
  margin: 1rem 0rem;
  padding: 0;
  display: block;
  overflow: hidden;
  /* position: absolute; */
  border-collapse: separate;
  font-family: "Open Sans", Calibri, Arial, sans-serif;
  background-color: #ffffff;
  /* box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3125); */
  /* -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3125); */
  border-radius: 3px;
  -webkit-border-radius: 3px;
}
.tail-datetime-calendar:after {
  clear: both;
  content: "";
  display: block;
  font-size: 0;
  visibility: hidden;
}
.tail-datetime-calendar.calendar-static {
  margin-left: auto;
  margin-right: auto;
}
.tail-datetime-calendar .calendar-navi {
  width: 100%;
  margin: 0;
  padding: 0;
  display: table;
  border-spacing: 0;
  border-collapse: separate;
  background-color: white;
  border-radius: 3px 3px 0 0;
  -webkit-border-radius: 3px 3px 0 0;
}
.tail-datetime-calendar .calendar-navi span {
  cursor: pointer;
  color: #3d4548;
  margin: 0;
  padding: 0;
  display: table-cell;
  font-size: 14px;
  text-align: center;
  line-height: 30px;
 /* text-shadow: -1px -1px 0 rgba(0, 0, 0, 0.15);*/
  background-repeat: no-repeat;
  background-position: center center;
}
.tail-datetime-calendar .calendar-navi span:first-child,
.tail-datetime-calendar .calendar-navi span:last-child {
  width: 35px;
  padding: 0 0 5px 0;
  font-size: 22px;
}
/* .tail-datetime-calendar .calendar-navi span:hover {
  background-color: rgba(0, 0, 0, 0.15);
} */
.tail-datetime-calendar .calendar-navi span.button-prev {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC\
    9zdmciIHdpZHRoPSI2IiBoZWlnaHQ9IjE2IiB2aWV3Qm94PSIwIDAgNiAxNiI+PHBhdGggZmlsbD0iI2ZmZmZmZiIgZD0iT\
    TYgMkwwIDhsNiA2VjJ6Ii8+PC9zdmc+");
}
.tail-datetime-calendar .calendar-navi span.button-next {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC\
    9zdmciIHdpZHRoPSI2IiBoZWlnaHQ9IjE2IiB2aWV3Qm94PSIwIDAgNiAxNiI+PHBhdGggZmlsbD0iI2ZmZmZmZiIgZD0iT\
    TAgMTRsNi02LTYtNnYxMnoiLz48L3N2Zz4=");
}
.tail-datetime-calendar .calendar-navi span.button-check {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC\
    9zdmciIHdpZHRoPSIxMiIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDEyIDE2Ij48cGF0aCBmaWxsPSIjZmZmZmZmIiBkP\
    SJNMTIgNWwtOCA4LTQtNCAxLjUtMS41TDQgMTBsNi41LTYuNUwxMiA1eiIvPjwvc3ZnPg==");
}
.tail-datetime-calendar .calendar-navi span.button-close {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC\
    9zdmciIHdpZHRoPSIxMiIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDEyIDE2Ij48cGF0aCBmaWxsPSIjZmZmZmZmIiBkP\
    SJNNy40OCA4bDMuNzUgMy43NS0xLjQ4IDEuNDhMNiA5LjQ4bC0zLjc1IDMuNzUtMS40OC0xLjQ4TDQuNTIgOCAuNzcgNC4y\
    NWwxLjQ4LTEuNDhMNiA2LjUybDMuNzUtMy43NSAxLjQ4IDEuNDhMNy40OCA4eiIvPjwvc3ZnPg==");
}
.tail-datetime-calendar .calendar-date {
  margin: 0;
  padding: 0;
  display: block;
}
.tail-datetime-calendar .calendar-date table {
  width: 100%;
  margin: 0;
  padding: 0;
  border-spacing: 0;
  border-collapse: collapse;
}

/* .tail-datetime-calendar .calendar-date table thead tr > *, */
/* .tail-datetime-calendar .calendar-date table tbody tr > * {
  color: black;
  width: 35px;
  height: 35px;
  padding: 0;
  font-size: 12px;
  text-align: center;
  font-weight: normal;
  line-height: 35px;
  border: 0;
  background-color: antiquewhite;
}
.tail-datetime-calendar .calendar-date table tbody tr > * {
  color: #334455;
  cursor: pointer;
  position: relative;
  font-size: 14px;
  background-color: #ffffff;
}
.tail-datetime-calendar .calendar-date table tbody tr > * > span {
  color: inherit;
  z-index: 10;
  position: relative;
} */
/* Select a Day */
.tail-datetime-calendar .calendar-date table tbody tr > *::after {
  top: 3px;
  left: 3px;
  width: 29px;
  height: 29px;
  content: "";
  display: block;
  position: absolute;
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  border-radius: 50%;
  -webkit-border-radius: 50%;
}
/* .tail-datetime-calendar .calendar-date table tbody tr > *.today {
  color: #3296c8;
}
.tail-datetime-calendar .calendar-date table tbody tr > *.today:after {
  border-color: #3296c8;
}
.tail-datetime-calendar .calendar-date table tbody tr > *:hover {
  color: #cd283c;
}
.tail-datetime-calendar .calendar-date table tbody tr > *:hover:after {
  border-color: #cd283c;
} */
.tail-datetime-calendar .calendar-date table tbody tr > *.empty,
.tail-datetime-calendar .calendar-date table tbody tr > *.disable {
  color: #8899aa;
  cursor: not-allowed;
  background-color: white;
}
.tail-datetime-calendar .calendar-date table tbody tr > *.empty:hover:after,
.tail-datetime-calendar .calendar-date table tbody tr > *.disable:hover:after {
  content: "";
  display: none;
}
.tail-datetime-calendar .calendar-date table tbody tr > *.current span {
  color: #fff;
}
.tail-datetime-calendar .calendar-date table tbody tr > *.current:after {
  border-color: #3296c8;
  background-color: #3296c8;
}
/* Select A Month */
.tail-datetime-calendar .calendar-date table.calendar-month tbody tr > * {
  width: 81.66666666666667px;
  padding: 5px;
  line-height: 25px;
}
.tail-datetime-calendar .calendar-date table.calendar-month tbody tr > * span {
  display: block;
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  border-radius: 3px;
  -webkit-border-radius: 3px;
}
.tail-datetime-calendar
  .calendar-date
  table.calendar-month
  tbody
  tr
  > *:hover
  span {
  border-color: #d0d0d0;
  box-shadow: 0 1px 0 0 #efefef;
  -webkit-box-shadow: 0 1px 0 0 #efefef;
}
/* Select a Time */
.tail-datetime-calendar .calendar-time {
  width: 100%;
  margin: 0;
  padding: 0;
  display: table;
  text-align: center;
  background-color: #ffffff;
  border-radius: 0 0 3px 3px;
  -webkit-border-radius: 0 0 3px 3px;
}
.tail-datetime-calendar .calendar-date + .calendar-time {
  border-top: 1px solid #d0d0d0;
  background-color: #f8f8f8;
}
.tail-datetime-calendar .calendar-time .calendar-field {
  width: 33.3333333333%;
  padding: 10px 0;
  display: table-cell;
  position: relative;
  text-align: center;
  vertical-align: top;
}
.tail-datetime-calendar .calendar-time .calendar-field:first-child {
  text-align: right;
}
.tail-datetime-calendar .calendar-time .calendar-field:first-child:after {
  top: 12px;
  right: -10px;
  content: ":";
  position: absolute;
}
.tail-datetime-calendar .calendar-time .calendar-field:last-child {
  text-align: left;
}
.tail-datetime-calendar .calendar-time .calendar-field:last-child:after {
  top: 12px;
  left: -10px;
  content: ":";
  position: absolute;
}
.tail-datetime-calendar .calendar-time .calendar-field input[type="number"] {
  width: 100%;
  max-width: 50px;
  margin: 0;
  padding: 3px 4px;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  border-width: 1px;
  border-style: solid;
  border-color: #d0d0d0;
  background-color: #ffffff;
  border-radius: 3px;
  -webkit-border-radius: 3px;
}
.tail-datetime-calendar
  .calendar-time
  .calendar-field
  input[type="number"]:hover {
  border-color: #a0a0a0;
}
.tail-datetime-calendar
  .calendar-time
  .calendar-field
  input[type="number"]:focus {
  border-color: #3296c8;
}
.tail-datetime-calendar .calendar-time .calendar-field label {
  color: #778899;
  margin: 5px 0 0 0;
  padding: 0;
  display: block;
  font-size: 12px;
  line-height: 12px;
}
/* @end tail.DateTime */

html{
  font-family: Spartan, sans-serif;
}

.containerEvents {
  display: flex;
  flex-direction: column;
}

.containerTitleSearch {
  display: flex;
}
.tittleEvent {
  color: black;
  padding-top: 12vh;
  padding-left: 15vh;
  font-weight: bold;
}

.inputSearch {
  margin-top: 11.6vh;
  margin-left: 10vh;
}
.img-noEvents{
  text-align: center;

  padding: 10rem;
}

.filterEvents{
  display: flex;
  justify-content: left;
  align-content: center;
  grid-gap: 50px;
  gap: 50px;
  padding-right: 50px;
  
}

.selectEvents{
color: rgb(153, 153, 153);
font-weight: 500;
background-color: black;
border: 1px solid;
border-radius: 2rem;
font-family: Spartan, sans-serif;
padding: 1.2rem 1.2rem 1.2rem 2.5rem;
width: 20rem;
-webkit-appearance: none;
        appearance: none;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYEAQAAAAa7ikwAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QAAKqNIzIAAAAJcEhZcwAAAGAAAABgAPBrQs8AAAAHdElNRQfmCAoUDxjc33avAAAApUlEQVRIx+3PvQ2DMBCGYR9DuEUypddI5QkyGLqGhhHSs4grCu/h+1IgJVJ+COBLAeIrbel5bWPOHX+A9wCRvksEeG9yH6MEZs0IQCSBOfcxGkhd59s4yrXrgKpSwS9tm4eUgKaZDpUiH/HHZWFkFi+NLMK3RlbhayOb8KWRIvxXRAX/FlHFny92Lg8pSWCWwDzhzqngrz9Rffl7xFqItX/Bz+13d7BN74H2iO0SAAAAJXRFWHRkYXRlOmNyZWF0ZQAyMDIyLTA4LTEwVDIwOjE1OjI0KzAwOjAw3GiK8wAAACV0RVh0ZGF0ZTptb2RpZnkAMjAyMi0wOC0xMFQyMDoxNToyNCswMDowMK01Mk8AAAAodEVYdGRhdGU6dGltZXN0YW1wADIwMjItMDgtMTBUMjA6MTU6MjQrMDA6MDD6IBOQAAAAAElFTkSuQmCC);
  background-repeat: no-repeat;
  background-position: right 1.5rem top 50%;
  background-size: 1.8rem auto;
}

.selectEvents:focus{

  color: black;
  background-color: #ebddd2;
}

.selectEvents option{
  margin-top: 20px;
  color: black;
  background-color: #ebddd2;
}

option{
  text-align: center;
}
.selectEvents.opened{
  background-color: blue;
}

#react-select-3-input{
  max-width: 0px !important;

}
.css-6j8wv5-Input{
  max-width: 0px !important;
  max-height: 0px !important;
 margin-left: 20px !important;
 margin-top: -10px !important;
 color: black !important;
}
.filterButtonOpen{
  color: black;
font-weight: 500;
background-color: white;
border: 1px;
display: flex;
  text-align: center;
  align-items: center;
font-family: Spartan, sans-serif;
padding-left: 5rem;
  padding-top: 2rem;
}

.filterButtonOpen:hover {
  cursor: pointer;
  -webkit-text-decoration: underline black;
          text-decoration: underline black; 
  color: black; /* Texto negro en hover */
  border-color: black; /* Borde negro */
}

.buttonContainer {
  display: flex;
  justify-content: space-between;  /* Espacio entre los botones */
  padding: 10px;
}


.filterButtonApply {
  color: rgba(255, 255, 255, 0.806);
  font-weight: 500;
  background-color: black;
  border: 1px solid black;
  
  font-family: Spartan, sans-serif;
  padding: 10px 20px;
  transition: all 0.3s ease;  /* Transición suave */
}

.filterButtonApply:hover {
  background-color: rgb(255, 255, 255); /* Fondo blanco al pasar el mouse */
  color: black; /* Texto negro en hover */
  border-color: black; /* Borde negro */
}

.filterButtonClean {
  color: black;
  font-weight: 500;
  background-color: white;
  border: 1px solid black;
  
  font-family: Spartan, sans-serif;
  padding: 10px 20px;
  transition: all 0.3s ease;  /* Transición suave */
}

.filterButtonClean:hover {
  background-color: black;  /* Fondo negro en hover */
  color: rgba(255, 255, 255, 0.806);/* Texto blanco en hover */
  border-color: black;  /* Borde negro */
}

.filterButtonApply:disabled {
  background-color: #cdcccc;  /* Cambiar el fondo a gris claro */
  color: #818181;  /* Cambiar el color del texto a gris */
  border-color: #9e9e9e;  /* Cambiar el color del borde a gris */
  cursor: not-allowed;  /* Cambiar el cursor a no permitido */
  opacity: 0.6;  /* Reducir la opacidad */
}
.filterButtonClean:disabled {
  background-color: #cdcccc;  /* Cambiar el fondo a gris claro */
  color: #818181;  /* Cambiar el color del texto a gris */
  border-color: #9e9e9e;  /* Cambiar el color del borde a gris */
  cursor: not-allowed;  /* Cambiar el cursor a no permitido */
  opacity: 0.6;  /* Reducir la opacidad */
}

.dividerStyle{
  width: auto;
  margin-left: 10px !important;
  margin-right: 10px !important;
}

.divFilter{
  width: 500px; 
  padding: 20px;
  z-index: 10
}

.bannerEventImg{
  width: 100%;
  height: auto; /* Mantener la proporción de la imagen */
display: block;
padding-top: 60px;
}
.containerTitleFilter{
  width: 80%;
  color: rgb(162, 161, 161);
  padding-left: 5rem;
  padding-right: 5rem;
}
.hrFilter{
  width: 100% !important;
  padding: 0 5rem !important;
  color: rgb(255, 255, 255) !important
}


@media (max-width: 500px) {
  .containerTitleSearch {
    align-self: center;
  }
  .tittleEvent {
    padding-top: 10vh;
    padding-left: 0;
    font-size: 22px;
    
  }

  .img-noEvents{
    text-align: center;
  padding: 5rem;
  width: 80vw;
  object-fit: cover;
  align-self: center;
    
  }
  .filterEvents{
    display: flex;
    justify-content: center;
    align-content: center;
   margin-bottom: 5px;
    border-top: 1px double  rgba(0,0,0,.08);
border-bottom: 1px double  rgba(0,0,0,.08);
    padding: 0px;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,.08);
-webkit-box-shadow: 0 2px 4px 0 rgba(0,0,0,.08);
-moz-box-shadow: 0 2px 4px 0 rgba(0,0,0,.08);
  }
  .divFilter{
    width: 65vw; 
    padding: 20px;
    z-index: 10
  }

  .filterButtonClean {
    width: 50%;
    margin-right: 2.5px;
  }
  .filterButtonApply{
    width: 50%;
    margin-left: 2.5px;
  }
  /*.buttonContainer{
    display: flex;
  justify-content: space-between;
  padding: 10px;
  bottom: 0;
  position: absolute;
  width: 60vw;
  }*/

  .bannerEventImg{
    width: 100%;
    height: auto; /* Mantener la proporción de la imagen */
  display: block;
  padding-top: 60px;
  }

  .filterButtonOpen{
    border: 1px;
    text-align: center;
    align-items: center;
    padding-left: 0;
    padding-top: 1rem;
  }

}

.react-container {
  margin-top: 80px;
  display: flex;
  flex: 2 1;
  flex-direction: column;
  font-family: Spartan, sans-serif;
}
.containerTittle {
  width: 43vw;
  height: 20vh;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 0 auto;
}
.button-space {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5vh;
}
.buttonForm {
  width: 50vw;
  background-color: black !important;
  color: white !important;
}
.tittle {
  font-size: 4vh;
}

.cart-ticket {
  background: #eaeaf0;
  border-radius: 10px;
  transition: all 0.25s linear;
  box-shadow: 4px 5px 2px 0px rgba(59, 57, 57, 0.4);
  margin: 0 19vh;
}
.ticket-header-d {
  margin: 0 4vh;
  float: right;
}
.ticket-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  /* row-gap: 10vh; */
  margin: 0 2vh;
}

.ticket-right {
  display: flex;
  margin: 5vh auto;
  flex-direction: column;
}

.ticket-left {
  display: flex;
  margin: 5vh auto;
  flex-direction: column;
}

.ticket-line-d {
  margin: 3vh 0vh 3vh 10vh;

  display: grid;
  justify-content: center;
}

.react-container2 {
  margin-top: 30px;
  display: flex;
  flex: 2 1;
  flex-direction: column;
  font-family: Spartan, sans-serif;
}
.containerTittle2 {
  width: 43vw;
  height: 30vh;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 0 auto;
}
.button-spacex2{
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5vh;
 }
.buttonForma2{
  width: 20%;
  background: black !important;
  color:white !important

}
.tittle2 {
  font-size: 3vh;
}

.cart-ticket2 {
  background: #eaeaf0;
  border-radius: 10px;
  transition: all 0.25s linear;
  box-shadow: 4px 5px 2px 0px rgba(59, 57, 57, 0.4);
  margin: 0 19vh;
}
.ticket-header2{
  margin: 4vh;
  float: right;
 
}
.ticket-container2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  /* row-gap: 10vh; */
  margin: 0 2vh;
}

.ticket-right2 {
  display: flex;
  margin:5vh auto;
  flex-direction: column;
}

.ticket-left2 {
  display: flex;
  margin: 5vh auto;
  flex-direction: column;
}

.ticket-line2{
  margin:2vh;
}
.cancelar-reserva2{
  display: flex;
  justify-content: center;
  justify-content: space-around;
  font-size: 20px;
}

@media (max-width: 600px) {

  .react-container2 {
    margin-top: 50px;
    display: flex;
    flex: 2 1;
    flex-direction: column;
    font-family: Spartan, sans-serif;
  }
  .containerTittle2 {
    width: 100%;
    height: 20vh;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 0 auto;
    margin-top:20px;
  }
  .button-spacex2{
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 5vh;
   }
  .buttonForma2{
    width: 60%;
    background: black !important;
    color:white !important
  
  }
  .tittle2 {
    font-size: 3vh;
  }
  
  .cart-ticket2 {
    background: #eaeaf0;
    border-radius: 10px;
    transition: all 0.25s linear;
    box-shadow: 4px 5px 2px 0px rgba(59, 57, 57, 0.4);
    margin: 0 15px;
  }
  .ticket-header2{
    margin: 4vh;
    float: right;
   
  }
  .ticket-container2 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    /* row-gap: 10vh; */
    margin: 0 2vh;
  }
  
  .ticket-right2 {
    display: flex;
    margin:5vh auto;
    flex-direction: column;
  }
  
  .ticket-left2 {
    display: flex;
    margin: 5vh auto;
    flex-direction: column;
  }
  
  .ticket-line2{
    margin:2vh;
  }
  .cancelar-reserva2{
    display: flex;
    justify-content: center;
    justify-content: space-around;
    font-size: 20px;
  }
  
}
.react-container {
  margin-top: 80px;
  display: flex;
  flex: 2 1;
  flex-direction: column;
  font-family: Spartan, sans-serif;
}
.containerTittle {
  width: 43vw;
  height: 20vh;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 0 auto;
}
.button-spacex {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5vh;
  width: 20%;
}
.buttonForma {
  width: 50vw;
  background: black !important;
  color: white !important;
}
.tittle {
  font-size: 4vh;
}

.cart-ticket {
  background: #eaeaf0;
  border-radius: 10px;
  transition: all 0.25s linear;
  box-shadow: 4px 5px 2px 0px rgba(59, 57, 57, 0.4);
  margin: 0 19vh;
}
.ticket-header {
  margin: 4vh;
  float: right;
}
.ticket-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  /* row-gap: 10vh; */
  margin: 0 2vh;
}

.ticket-right {
  display: flex;
  margin: 5vh auto;
  flex-direction: column;
}

.ticket-left {
  display: flex;
  margin: 5vh auto;
  flex-direction: column;
}

.ticket-line {
  margin: 2vh;
}

.react-container {
  margin-top: 80px;
  display: flex;
  flex: 2 1;
  flex-direction: column;
  font-family: Spartan, sans-serif;
}
.containerTittle {
  width: 43vw;
  height: 20vh;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 0 auto;
}
.button-space {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5vh;
}
.buttonForm {
  width: 50vw;
  background-color: black !important;
  color: white !important;
}
.tittle {
  font-size: 4vh;
}

.cart-ticket {
  background: #eaeaf0;
  border-radius: 10px;
  transition: all 0.25s linear;
  box-shadow: 4px 5px 2px 0px rgba(59, 57, 57, 0.4);
  margin: 0 19vh;
}
.ticket-header-d {
  margin: 0 4vh;
  float: right;
}
.ticket-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  /* row-gap: 10vh; */
  margin: 0 2vh;
}

.ticket-right {
  display: flex;
  margin: 5vh auto;
  flex-direction: column;
}

.ticket-left {
  display: flex;
  margin: 5vh auto;
  flex-direction: column;
}

.ticket-line-d {
  margin: 3vh 0vh 3vh 10vh;

  display: grid;
  justify-content: center;
}

body,
html {
  margin: 0px;
}

html {
  scroll-padding: 60px;
}

h1,
h2,
h3 {
  font-weight: lighter;
}


