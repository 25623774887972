body,
html {
  margin: 0px;
}

html {
  scroll-padding: 60px;
}

h1,
h2,
h3 {
  font-weight: lighter;
}

