

.react-container {
  margin-top: 80px;
  display: flex;
  flex: 2;
  flex-direction: column;
}

.container-center-image {
  display: flex;
  flex: 1;
  justify-content: center;
  max-height: 60vh;
}

.center-image {
  margin: 2rem 0;
  /* min-width: 30vw; */
  width: 60vw;
  height: 45vh;
}
.imgbanner{
  object-fit: contain;
  width: 100%;
}

select {
  font-family: Spartan, sans-serif;
  font-size: 18px !important;
  height: 3rem;
  border-radius: 3rem !important;
}



option {
  font-size: 18px !important;
}

.buttonFormAgenda {
 
  height: 2.5rem !important;
  background-color: black !important;
  color: white !important;
  margin: 4vh auto;
  font-size: 18px !important;
  border-radius: 2rem !important;
}

.containerButton {
  display: flex;
  order: 4;
 
  margin-top: 2rem;
  align-self: center;
}
.eventTitle{
  text-align: center;
  font-size: larger;
  font-weight: bold;
}

.eventDescription{
 text-align: center;
  font-size: large;
  width: 50%;
  align-self: center;

}
.calendar-container {
  /* flex: 1; */
}

.divCalendar {
  padding: 0.5rem;
}


.calendar {
  display: flex;
  flex: 8;
  flex-direction: row;
  justify-content: center;   
  background-color: #D8D6D6;
}



.zone {
  
  position: relative;
  padding: 12px 19px;
  cursor: pointer;
}

.zone::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 50%; /* Ancho del borde más corto */
  border-bottom: 2px solid #000; /* Estilo del borde */
  display: none; /* El borde está oculto por defecto */
}

.zone.active::after {
  display: block; /* Muestra el borde solo cuando "active" es true */
}

.form-calendar {
  display: flex;
  flex: 3;
  flex-direction: row;
  justify-content: center;
  padding: 4vh 20vw;
  background-color: #EFEFEF;
  
}

.input-container {
  /* height: 77vh; */
  display: flex;
  /*justify-content: center;*/
  flex-direction: column;
  max-width: 80%;
  justify-content: space-around;
  font-size: 20px;
}
.div_form {
  min-width: 50%;
}

.grid-container {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.grid-input {
  display: flex;
  flex-direction: column;
  padding: 0.5em;
}

.input {
  border-radius: 3rem !important;
  height: 3rem !important;
  border: none;
  font-size: 20px;
  padding-left: 1rem;
  padding-right: 1rem;
  color: black !important;
}

.label {
  font-size: 1rem !important;
  font-weight: 100 !important;
  padding: 0px 0px 5px 15px !important;
}

.order {
  flex-grow: 3;
  /*padding: 2vh 4.5vh;*/
  /*flex-flow: row wrap;*/
}

.grid-calendario {
  width: auto !important;
  /*display: grid;
  grid-template-rows: 1fr 1fr;
  /* row-gap: 10vh; 
  width: 20% !important;
  /* flex-direction: column; 
  height: 33vh;*/
  margin-bottom: 1rem;
}

.button-save {
  border-radius: 50px;
  background-color: black;
  color: white;
  font-size: 16px;
  width: 190px;
  height: 30px;
}

.container-view {
}

.grid-time {
  display: flex;
  width: auto !important;
  flex-direction: column;
  /* height: 38vh; */
 
}

.grid-item-time {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr !important;
  overflow-y: hidden !important;
  margin-bottom: 2rem !important;
}

.item-time {
  display: flex;
  /* width: 30%; */
  border-radius: 30px !important;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 16px !important;
  padding: 15px 25px !important;
  background-color: aliceblue;
  margin: 5px 8px !important;
  flex-grow: 2;
}

.item-time:hover {
  background-color: #dddddd !important;
}

/* .view-calendar {
  border-radius: 10px;
  background-color: aliceblue;
  width: 35vh;
  margin: auto;
  height: 35vh;
} */

.view-calendar {
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
  user-select: none;
  width: 100vw;
  padding: 10px;
  padding-top: 0;
}

.labelMsg{
  
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  
  white-space: pre-line;
  line-height: 1.6em;
  text-align: center;

}

.sucursal{
  margin-bottom: 2rem;
order: 1;
}



@media (min-width: 480px) {
  .view-calendar {
    height: auto;
    width: 340px;
    max-width: 100%;
  }
}

.month-container {
  width: 100%;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr;
  -ms-grid-rows: 1fr;
}

.container-save-btn {
  margin-top: 5vh;
  margin-top: vh;
  justify-content: center;
  display: flex;
}



@media (max-width: 500px) {
  .calendar {
    justify-content: center ;
    overflow-y: auto !important;
  }
  .zone {
    display: ruby;
    display: ruby-text;
  }
  .form-calendar {
    display: -webkit-flex;
    flex-direction: column;
    height: auto;
    padding: 0;
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  .order {
    max-width: 100vw;
  }
  .input-container {
    order: 3;
  }

  .grid-time {
    display: grid;
    order: 2;
    width: auto !important;
    height: auto !important;
    margin-top: 1rem;
    padding: 0.5rem;
  }
  .containerButton {
    align-self: center;
    width: 50%;
    margin-top: 1rem !important;
  }
  .grid-calendario {
    grid-template-rows: 1fr;
    order: 1;
    width: 100% !important;
    margin-bottom: 1rem !important;
  }
  .divCalendar {
    padding: 0.5rem;
    
  }
  .center-image {
    width: 100vw !important;
    height: 35vh !important;
    background-size: cover !important;
    margin:  0 !important;
  }
  .react-container {
    margin-top: 0 !important;
  }

  .div_form {
    min-width: 90% !important;
  }

  .grid-item-time {
    display: grid;
    grid-template-columns: 1fr 1fr  !important;
    flex-flow: row wrap;
    margin-bottom: 2rem !important;
    overflow-y: hidden !important;
    padding-left: 5px;
  }
 
  .item-time {
    height: 2vh;
    width: 30vw;
  
  }

  .eventTitle{
    font-size: small;
    margin-top: 4rem;
  }
  .eventDescription{
    width: 80%;
  }

  .container-center-image {
    max-height: 50vh;
    max-width: 99vw;
  }
  .imgbanner{
    object-fit: contain;
    width: 100%;
  }
  .imgbannermobile{
    max-width: 95vw;
  }

  .label {
    padding-left: 1rem !important;
  }
  .bannerEvent{
    margin-top: 2px;
  margin-bottom: 2px;
  }

  .sucursal{
    margin-bottom: 1rem;

  }
}

