.conteinerMain {
  background: #ffffff;
}

.containerCard {
  margin: 3vw 15vw 3vw 15vw;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 10px;
  row-gap: 10px;
  
}

.card {
  background: #ffffff;
  border: solid 1px black;
  border-radius: 10px;
  display: inline-block;
  transition: all 0.25s linear;
 padding: 10px;
  margin: 2vh;
  display: grid;
  grid-template-columns: 1fr 1fr;
  /* height: 35vh; */
}

.card:hover {
  box-shadow: -1px 10px 29px 0px rgba(39, 39, 41, 0.8);
}

.leftSideRows {
  display: flex;
  justify-content: center;
  align-items: center;

}

.leftStyle {
  /*width: 45vh;
  height: 30vh;*/
  display: flex;
}

.imageEvent {
  width: 100%;
  
  border-radius: 7px;
}

.rightSideRows {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto auto;
  /*padding-left: 2rem;
  padding-right: 2rem;*/
}
.containerBrands {
  display: grid;
  grid-template-columns: auto auto auto;
  /*column-gap: 1vh;
  row-gap: 1vh;
  margin-top: 2vh;*/
  justify-content: center;
  align-content: center;
  padding-left: 5px;
}

.upSide {
  /* width: 40vh;
  height: 18vh; */
  /* margin: 1vh auto; */
  align-content: center;
}
.imageBrand {
  width: 100%;
  /*height: 80%;
  object-fit: contain;*/
}

.downSide {
  /*margin: 2vh auto;
  justify-content: center;*/
  display: flex;
  flex-direction: column;
  align-items: center;
 /* padding: 0 0.5em 0 0.5em;*/
  justify-content: space-evenly;
  padding-left: 0.8rem;
  padding-right: 0.8rem;
}
.tittle {
  font-size: 1.5vw !important;
  font-weight: 500;
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: center;
  max-width: 15ch;
}
.description {
  font-size: 1vw;
  font-weight: 300;
  text-align: center;
  margin: 1px;
  min-height: 2em;
}

.button {
  padding: 5px 10px 2px 10px;
  background-color: black;
  color: white;
  cursor: pointer;
  border-radius: 15px;
  border: solid black;
  text-align: center;
  align-items: center;
  display: flex;
  
}


hr{

  width: 50%;
  background-color: black;
}

@media (max-width: 500px) {
  .containerCard {
    grid-template-columns: 100vw;
    margin: 0;
    margin-top: 2vh;
    margin-bottom: 2vh;
    row-gap: 20px;
  }
  .card {
    grid-template-columns: 1fr 1fr;
    box-shadow: 0px 2px 5px 2px rgba(59, 57, 57, 0.4);
   margin: 0vh 2vh 0vh 2vh;
  }
  .leftStyle {
    width: 100%;
    display: flex;
  }
  .upSide{
    justify-content: center;
  display: flex;
  }

  .imageEvent {
    border-radius: 7px 0px 0px 7px;
  }
  .button{
    width: auto !important;
    height: 3vh !important;
    font-size: 3vw !important;
  }
  .rightSideRows {
    grid-template-rows: auto auto auto;

  }
  .downSide {
    justify-content: space-evenly;
    margin-top: 1vh;
  }
  .containerBrands{
    margin: 0;
  }
  .imageBrand{
    width: 70%;
  }
  .description {
    font-size: 2.5vw;
    font-weight: 400;
    text-align: center;
    margin: 5px;
    min-height: 4em;
  }
  .tittle {
    font-size: 2vh !important;
    font-weight: 550;

  }

  hr{
    height: 0px;
   
  }
}
