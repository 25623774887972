


@media (max-width: 600px) {
  .fiCardContentR {
  
    height: 40vw !important;
    justify-items: center;
    align-items: center;
 
  }

  /*.fiCardContentR:hover {
    background: gba(0, 0, 0, 0.24);
    width: 80vw !important;
    height: 10vh !important;
  }*/
}
