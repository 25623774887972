.title{
    text-align: center;
    margin-top: 5rem;
    margin-bottom: -2rem;
}

.title-text{
    font-weight: 800;
}
@media (max-width: 500px) {
    .title{
        
        margin-top: 1.5rem;
        margin-bottom: 1rem;
    }
    
    .title-text{
        font-size: 5vw;
    }
}